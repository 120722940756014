<template>
  <div class=" ">
    <titleBar
      title="Image Creator"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="true"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />
    <div class="section">
      <createTabs v-if="false" />

      <div class="container w1200" v-if="!loading">
        <div class="columns is-variable">
          <div class="column" style="padding-right: 30px">
            <h3 class="title is-3">
              Image Creator

              <DotDrop
                :items="[
                  { t: 'Explore templates', icon: 'far fa-drafting-compass', to: `explore` },

                  { t: 'Search AI images', icon: 'far fa-search', to: `/search?q=&v=images` },
                  { t: 'Create posts for your bots', icon: 'far fa-user-friends', to: `/bots` },
                ]"
              />
            </h3>

            AxggUuQPD
            {{ template }}

            <templatePill :item="template" :closable="true" @clear="clearTemplate" style="margin-bottom: 10px" />

            <searchImagine :fullview="true" :templates="templates" :noDescription="false" :pageSize="9999" />
          </div>
        </div>
      </div>

      <div class="tabs is-centeredNO is-boxedNO">
        <ul>
          <li v-for="tab in bottomTabs" :key="tab.id" :class="{ 'is-active': isActive(tab.id) }">
            <a @click="selectTab(tab.id)">
              {{ tab.name }}
            </a>
          </li>
        </ul>
      </div>

      <div v-if="activeTabId == 'posts' && posts && posts.length" class="templates" key="tab431">
        <vue-masonry-wall
          :items="posts.slice(0, 40)"
          :options="{
            width: postColWidth,
            padding: {
              default: 6,
              1: 2,
              2: 2,
              3: 3,
            },
          }"
        >
          <!--  @append="append"-->
          <template v-slot:default="{ item }">
            <div class="item">
              <router-link :to="item.url" v-if="item.url && item.featuredImage">
                <b-image
                  v-if="item.featuredImage && item.featuredImage.url"
                  :src="item.featuredImage.url300"
                  :placeholder="item.featuredImage.url50"
                  ratio="1by1"
                  :ratio-todo="item.featuredImage.ratio"
                ></b-image>
              </router-link>
              <h5>{{ item.lavel }}</h5>
              <p>{{ item.name }}</p>
            </div>
          </template>
          {{ posts }}ttttt
        </vue-masonry-wall>
      </div>

      <div v-if="activeTabId == 'templates' && templates && templates.length" class="templates" key="tab4313534">
        <vue-masonry-wall :items="templates" :options="{ width: 300, padding: 12 }" @append="append">
          <template v-slot:default="{ item }">
            <router-link class="item box" :to="item.url">
              <div
                :key="item.index + 'tag' + index"
                @click="select(item.index)"
                ref="tags"
                class="round-tag"
                :class="{ 'selected-tag': item.isActive }"
                v-show="item.visible"
              >
                <img :src="item.image || item.avatar" alt="" class="avatar img" style="display: inline-block" />
                <span class="lab">{{ item.name }}</span>
              </div>
            </router-link>
          </template>
          {{ templates }}2222222
        </vue-masonry-wall>
      </div>

      <div v-if="activeTabId == 'history' && history && history.length" class="history" key="tab747777831">
        <vue-masonry-wall :items="templates" :options="{ width: 300, padding: 12 }" @append="append">
          <template v-slot:default="{ item }">
            <div class="item box">
              aaa3333
              <h5>{{ item.lavel }}</h5>
              <p>{{ item.name }}</p>
            </div>
          </template>
        </vue-masonry-wall>
      </div>

      {{ selectedTab }}

      <div class="container">
        <br />

        <!--  
            
            :actions="mobileActions"WORKING TABS 
            
      <section>
        <b-tabs v-model="activeTab" type="is-boxed" @input="navigate">
          <b-tab-item v-for="(tab, index) in tabs" :key="index" :label="tab.label"> </b-tab-item>
        </b-tabs>
      </section>

      -->
        <empty
          v-if="!items.length && !loading"
          title="Save posts for later"
          sub="Don't let the good ones fly away! Bookmark posts to easily find them again in the future."
          img="https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/Jiku4-wAY/d9ko24iOPT____e99be2c4-1ec6-4ed6-8351-7b8c43397194.jpeg"
        />

        <div class=" " v-if="items.length">
          <h3 class="title is-3">
            Bookmarks
            <!--  
            <DotDrop
              :items="[
                { t: 'Popular', icon: 'far fa-fire', to: `soon` },
                // { t: 'Refresh', icon: 'far fa-sync', click: alert },
                { t: 'Following', icon: 'far fa-user-plus', to: `soon` },
              ]"
            />
            -->
          </h3>

          <!--  
          <NewPostTeaser v-if="$store.main.state?.profile?.hasPages" />
         

          <postList :items="items" :pageSize="200" @bookmarkRemoved="bookmarkRemoved" style="max-width: 550px" />
           -->
          <!--   
        <router-link to="/bots/create">
          <b-button rounded> <i class="fal fa-plus" style=""></i> <span> </span>New page </b-button>
        </router-link>-->
        </div>

        <br />
        <br />
        <div class=" " v-if="!items.length && !loading"></div>
      </div>
    </div>
  </div>
</template>

<script>
//import postList
import postList from "@/components/PostList.vue";
import DotDrop from "@/components/e/DotDrop.vue";
import createTabs from "@/components/nav/createTabs.vue";

import NewPostTeaser from "@/components/studio/NewPostTeaser.vue";

import searchImagine from "@/components/search/searchImagine.vue";

import templatePill from "@/components/e/templatePill.vue";

import VueMasonryWall from "vue-masonry-wall";

export default {
  data() {
    return {
      items: [],
      activeTab: 0, // Index of the active tab
      loading: true,
      tabs: [
        { route: "/following", label: "Following" },
        { route: "/latest", label: "Latest" },
        { route: "/trending", label: "Trending" },
        // Add more tabs here
      ],

      activeTabId: this.$route.query ? "preview" : "posts", // Default to first tab
      templates: [],
      template: null,
      history: [],
      posts: [],
      postColWidth: (window.innerWidth / 6 + 200) / 2 || 200,
    };
  },
  components: {
    // postList,
    createTabs,
    VueMasonryWall,
    templatePill,
    searchImagine,
    DotDrop,
    //  DotDrop,
    // NewPostTeaser,
  },
  computed: {
    selectedTab() {
      return this.bottomTabs.find((tab) => tab.id === this.activeTabId);
    },
    bottomTabs() {
      return [
        { id: "preview", name: "Template preview", visible: this.template },
        { id: "posts", name: "Explore ideas", content: "Explore ideas content here..." },
        { id: "templates", name: "Templates", content: "Templates content here..." },
        { id: "history", name: "Creations", content: "Creations content here..." },
        { id: "help", name: "Help", icon: "Help" },
      ];
    },
  },
  methods: {
    load() {
      //   const response = await fetch("/api/bots");
      //   const botTemplates = await window.API.me.getBotBlueprint();
      this.loading = false;
      //this.items = data;
      var params = this.$route.query;
      var bot = params.bot;
      var template = params.template;
      var q = params.q;
      if (bot && template) {
        window.API.getBotBlueprint(bot, template).then((res) => {
          this.template = res;
        });
        window.API.getSuggestedTemplates({ bot }).then((res) => {
          this.templates = res;
        });
      }

      window.API.getGlobalPostFeed({ limit: 30 }).then((data) => {
        this.posts = data;
      });
    },
    clearTemplate() {
      this.bot = null;
      this.template = null;
    },
    append() {
      //method called when we append stuff to mosaic
    },
    selectTab(id) {
      this.activeTabId = id;
      if (this.activeTabId == "preview") this.activeTabId = "posts";
    },
    isActive(id) {
      return this.activeTabId === id;
    },
    getBotBlueprint(post) {
      //   alert("Bookmark removed" + post);
      //this.template = await window.API.me.getBotBlueprint();
    },
    /*
    navigate(newIndex) {
      //  this.$router.push(this.tabs[newIndex].route);
    },*/
    truncate(text2, length) {
      if (!text2) return;
      var text = String(text2);
      if (text.length > length) {
        return String(text).substring(0, length) + "...";
      }
      return text;
    },
    viewBot(bot) {
      // Code to view bot details
    },
    manageBot(bot) {
      // Code to manage bot settings
    },
  },
  mounted() {
    this.load();
    const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
    this.activeTab = tabIndex === -1 ? 0 : tabIndex;
  },
  watch: {
    "$route.path"() {
      const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
      this.activeTab = tabIndex === -1 ? 0 : tabIndex;
    },
  },
};
</script>

<style>
.botTable .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.botTable td {
  vertical-align: middle;
}
</style>
